/* global  itemVarietiesComponent */
class CustomItemVarietiesComponent extends itemVarietiesComponent {

    getData() {
        let superData = super.getData();
        return function () {
            return Object.assign(superData(),{
                isMobile: $(window).width() <= 991
            });
        };
    }

    getTemplate(){
        return `<div class="item-detail" @change.once="loadVarities">
                <div class="col-12">
                   <div class="container-fluid">
                    <div class="row" v-if="item">
                        <div class="col-sm-12 col-md-6 col-lg-5 offset-lg-1">
                            <template v-if="!selected || !selected.images">
                                <itemSliderComponent :carouselOnLeftSide="!isMobile" :useZoom="imgUseZoom && !isMobile" :slideBigImage="imgSlideBigImage" :item="item" :stockFlag="showStock" :container="true" :showCarusel="imgShowCarusel"></itemSliderComponent>
                            </template>
                            <template v-else>
                                <itemSliderComponent :carouselOnLeftSide="!isMobile" :useZoom="imgUseZoom && !isMobile" :slideBigImage="imgSlideBigImage" :item="selected" :stockFlag="showStock" :container="true" :showCarusel="imgShowCarusel"></itemSliderComponent>
                            </template>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <p class="product-title">{{item.Name}}</p>
                            <div class="product-description">
                                <p class="subtitle">{{tr("Description")}}</p>
                                <p class="product-comment" v-if="item.Comment">{{item.Comment}}</p>
                                <p v-else>{{tr("Article without description.")}}</p>
                            </div>
                            <div class="product-details">
                                <p class="product-code">{{tr("ECOMITEMCODE")}} {{codeToShow}}</p>
                                <p class="product-code" v-if="$store.getters.showStock">
                                    <template v-if="!selected">
                                         Stock {{item.Qty}}
                                    </template>
                                    <template v-else>
                                        Stock {{selected.Qty}}
                                    </template>  
                                </p>
                                <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                    <p class="item-price" v-if="!loadingPrice">
                                         <template v-if="!selected">
                                            <template v-if="item.Qty - item.Reserved > 0">
                                                Stock {{getMaxStockByCode(item.Code)}}
                                            </template>
                                            <template v-else>
                                                Stock <span class="badge-dark px-1">Agotado</span>
                                            </template>
                                         </template>
                                         <template v-else>
                                            <template v-if="getMaxStockByCode(selected.Code) > 0">
                                               Stock {{selected.Qty - selected.Reserved}}
                                            </template>
                                            <template v-else>
                                                Stock <span class="badge-dark px-1">Agotado</span>
                                            </template>
                                         </template>  
                                    </p>
                                </template>
                                <template v-if="showClassification">
                                    <p class="subtitle">{{tr('Classifications')}}</p>
                                    <ol class="flex-column">
                                         <template v-for="cat in classificationList">
                                            <li :key="'item-cat-'+cat.name" @click="filterByCategory(cat)">
                                                <router-link :to="'/items?category=' + encodeURI(cat.code+'__'+cat.name)">
                                                    {{capitalize(cat.name)}}
                                                </router-link>
                                            </li>
                                        </template>
                                    </ol>
                                </template>
                            </div>
                            <template v-if="itemVarieties.length==0">
                                <div class="loading" >
                                    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row items-varieties" >
                                    <div class="col-12">
                                        <template v-for="option in Object.keys(options).sort()">
                                            <template v-if="option=='COLOR'">
                                                <div class="form-group justify-content-center row">
                                                    <label class="col-12 text-center" :for="'optionSelect'+option" >{{capitalize(option)}}</label>
                                                    <div class="row col-10 col-md-5 col-lg-4 item-color-options" >
                                                        <template v-for="optionKey in Object.keys(options[option]).sort()" >
                                                            <div class="col px-1" >
                                                                <template v-if="imageByVariation[option+'-'+optionKey]">
                                                                     <template v-if="selected && selected.Variants && selected.Variants.split(',').indexOf(optionKey)!==-1 && selected.images && selected.images.length > 0">
                                                                        <img :title="options[option][optionKey]" :key="optionKey"  :id="optionKey" :src="selected.images[0]" :alt="options[option][optionKey]" class="rounded rounded-circle"  v-bind:class="{ 'color-active': selectedColor==optionKey }"  width="40" height="40" @click="(event)=>selectVariation(event,option)">
                                                                    </template>
                                                                    <template v-else>
                                                                        <img :title="options[option][optionKey]" :key="optionKey"  :id="optionKey" :src="imageByVariation[option+'-'+optionKey]" :alt="options[option][optionKey]" class="rounded rounded-circle"  v-bind:class="{ 'color-active': selectedColor==optionKey }"  width="40" height="40" @click="(event)=>selectVariation(event,option)">
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <img :title="options[option][optionKey]" :key="optionKey"  :id="optionKey" :src="'../assets/'+optionKey+'_'+options[option][optionKey].replace(' ','')" :alt="options[option][optionKey]" class="rounded rounded-circle"  v-bind:class="{ 'color-active': selectedColor==optionKey }"  width="40" height="40" @click="(event)=>selectVariation(event,option)">  
                                                                </template>
                                                            </div>
                                                         </template>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="form-group row">
                                                <label class="col-auto col-form-label"  :for="'optionSelect'+option">{{capitalize(option)}}</label>
                                                <div class="col-sm-8 col-md-auto">
                                                    <select :id="'optionSelect'+option" class="form-control"  v-on:change="(event)=>selectVariation(event,option)">
                                                        <option :value="null" selected disabled ></option>
                                                        <template v-for="optionKey in Object.keys(options[option]).sort()">
                                                            <option :value="optionKey" >{{options[option][optionKey]}}</option>
                                                        </template>
                                                    </select>
                                                </div>
                                             </div>
                                             </template>
                                        </template>
                                        <template v-if="selected">
                                            <p> Cod.Variante : {{selected.Code}}</p>
                                            <div class="row product-shops">
                                                    <template v-if="alertMsg">
                                                        <div class="col-12 alert alert-danger" role="alert">{{tr(alertMsg)}}</div>
                                                    </template>
                                                    <div class="input-group qty-input col-4 col-sm-6 col-xl-3 col-md-5 pr-0 pr-xl-4">
                                                        <template v-if="controlStock">
                                                            <template v-if="getMaxStockByCode(selected.Code)">
                                                                <div class="input-group-prepend d-none d-sm-flex" >
                                                                    <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                                </div>
                                                                <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(selected.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                                <div class="input-group-append d-none d-sm-flex">
                                                                    <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <input :ref="'carCant-'+item.Code" v-model="Qty" min='0' :max="0" class="form-control inputch" type="text" disabled>
                                                                <small id="withOutStockHelpBlock" class="form-text text-muted">
                                                                    {{tr("Without Stock")}}
                                                                </small>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <div class="input-group-prepend d-none d-sm-flex" >
                                                                <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                            </div>
                                                            <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(selected.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                            <div class="input-group-append d-none d-sm-flex">
                                                                <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <span class="cart-button col-8 col-sm-6 col-xl-12 col-md-4 pl-sm-0 pl-sm-3 text-left mt-0 mt-xl-2">
                                                        <button class="btn secondary-btn px-1 px-sm-3 px-xl-5" :disabled="getMaxStockByCode(selected.Code)" type="button" @click.stop="canShopItem ? addItemToCar() : null"><span class="icon"><i class="fa-icon fas fa-cart-plus"></i></span> {{tr("Load to Cart")}}</button>
                                                    </span>
                                                </div>
                                        </template>
                                        <div class="social-buttons col-12 text-center text-md-left mt-4 p-0 pl-md-3">
                                            <a v-if="enableFB" class="social-button mr-1" title="Facebook"  target="_blank" rel="nofollow"  :href="'https://www.facebook.com/sharer.php?u='+getItemUrl()" >
                                                <i class="icon fab fa-facebook-square"></i>
                                            </a>
                                            <a v-if="enableTW" class="social-button" title="Twitter" target="_blank" rel="nofollow" :href="'https://twitter.com/intent/tweet?url='+getItemUrl()">
                                                <i class="icon fab fa-twitter-square"></i>
                                            </a>
                                            <a v-if="enableIG" class="social-button" title="Instagram" target="_blank" rel="nofollow" :href="linkIG" >
                                                <i class="icon fab fa-instagram-square"></i>
                                            </a>
                                             <a v-if="enableLK" class="social-button" title="LinkedIn" target="_blank" rel="nofollow" :href="linkLK" >
                                                <i class="icon fab fa-linkedin"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="w-100 px-3">
                            <div class="mt-3" v-if="relatedItems.length>0">
                                <homeItemCardComponent :section="{internalId:item.Code}" :items="relatedItems" :title="tr('Related Items')" :titleCenter="true" :fullScreen="true"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
			</div>`;
    }
}

CustomItemVarietiesComponent.registerComponent();